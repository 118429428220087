import React from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { baseUrl, apiUrl } from '../../../config/apiConfig';
import { formatedDate } from '../../../helper/time';
import { toast } from 'react-toastify';

const BlogCommentArea = ({ blogComments, blogInfo, blogCommentsCount, setblogComments, setblogCommentsCount }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: 'onChange',
	});
	const alert = toast;

	const handleCommentsSubmit = (formData, e) => {
		let bodyFormData = new FormData();
		bodyFormData.append('email', formData.email ? formData.email : '');
		bodyFormData.append('name', formData.name ? formData.name : '');
		bodyFormData.append('message', formData.message ? formData.message : '');
		bodyFormData.append('blog_id', blogInfo && blogInfo.id);

		axios({
			method: 'post',
			url: apiUrl + '/api/postblogComment',
			data: bodyFormData,
			headers: { 'Content-Type': 'multipart/form-data' },
		})
			.then((response) => {
				// console.log("data", response);

				setblogComments(response.data.data.blog_comments);
				setblogCommentsCount(response.data.data.blog_commentcount);

				alert.info('Blog Comment added');
				e.target.reset();
			})
			.catch((error) => {
				// console.log(error);
			});
	};

	return (
		<>
			<div className="blog-comments-area">
				{blogCommentsCount > 0 && (
					<div className="comments-list-wrapper">
						<h3 className="comments-title"> {blogCommentsCount} Comments </h3>
						<div className="comments-list-area">
							{blogComments &&
								blogComments.length > 0 &&
								blogComments.map((blogCommentInfo, index) => {
									return (
										<div className="single-comment-area mb-4" key={index}>
											<div className="single-comment-user-image-info d-flex align-items-center mb-2">
												<div className="single-comment-user-image">
													<img src={baseUrl + '/images/user.svg'} alt="" />
												</div>
												<div className="single-comment-user-info">
													<h3 className="name-and-date mb-1">{blogCommentInfo.name}</h3>
													<div className="date">{formatedDate(blogCommentInfo.created_at, 'MMMM Do YYYY, h:mm:ss a')}</div>
												</div>
											</div>
											<div className="single-comment-description">
												<p>{blogCommentInfo.message}</p>
											</div>
										</div>
									);
								})}
						</div>
					</div>
				)}

				<h3 className="comments-title pt-5">Post a Comment</h3>
				<div className="blog-comments-form">
					<form onSubmit={handleSubmit(handleCommentsSubmit)}>
						<div className="row ">
							<div className="col-lg-6">
								<div className="form-group mb-3">
									<input
										type="text"
										className="form-control"
										required
										id="comment-name"
										name="name"
										placeholder="Name"
										{...register('name', {
											required: 'Required',
										})}
									/>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="form-group mb-3">
									<input
										type="email"
										className="form-control"
										required
										id="comment-email"
										name="email"
										placeholder="Email"
										{...register('email', {
											required: 'Required',
										})}
									/>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-12">
								<div className="form-group mb-3">
									<textarea
										className="form-control"
										required
										id="comment-message"
										rows="7"
										name="message"
										placeholder="Your Comment"
										{...register('message', {
											required: 'Required',
										})}
									></textarea>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-12">
								<div className="form-group mb-2">
									<button
										type="submit"
										className="btn-submit d-inline-block fs-18 lh-32 btn-gradient btn-gradient-blue-light-blue reverse position-relative hover overflow-hidden text-white px-4 py-3 radius-12"
									>
										<span className="icon me-3">
											<FontAwesomeIcon icon={faLocationArrow} />
										</span>
										<span className="text">Submit</span>
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default BlogCommentArea;

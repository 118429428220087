import React from "react";
import { apiUrl } from "../../../config/apiConfig";

function BlogJsonLD({ blogDetails }) {
  function addBlogJsonLd() {
    return {
      __html: `
                {
                "@context": "https://schema.org",
                "@type": "NewsArticle",
                "headline": ${JSON.stringify(blogDetails.blogData.blog_name)},
                "image": [
                    "${apiUrl + blogDetails.blogData.image}"
                ],
                "datePublished": "${blogDetails.blogData.created_at}",
                "dateModified": "${blogDetails.blogData.created_at}",
                "author": [
                    {
                        "@type": "Person",
                        "name": "${blogDetails.blogData.author_info.name}",
                        "url": "https://www.updatetechltd.com/blog/author/${
                          blogDetails.blogData.author_info.id
                        }"
                    }
                ]
                }
            `,
    };
  }

  return (
    <>
      <link />
      {blogDetails && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={addBlogJsonLd()}
          key="product-jsonld"
        />
      )}
    </>
  );
}

export default BlogJsonLD;

import React from "react";
import * as Scroll from 'react-scroll';
import { Link } from 'react-scroll'

const PageOutline = ({ outlines }) => {
  return (<>
    {outlines && outlines.length > 0 &&
      <div className="page-sidebar-box mt-0">
        <h3>Outline</h3>
        <div className="outline-list">
          <ul className="d-flex flex-column">
            {outlines.map((outline, index) => {
              return (
                <li key={index} className="mb-2">
                  <Link activeClass="active" to={outline.id} spy={true} smooth={true} offset={-80} duration={100} >
                    {outline.text}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    }
  </>);
};

export default PageOutline;

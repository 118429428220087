import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { apiUrl } from "../../../config/apiConfig";
import Link from "next/link";

const BlogDetails = ({ blogData }) => {
  const [tableOfContents, setTableOfContents] = useState([]);

  useEffect(() => {
    if (blogData?.long_description) {
      // Extract headings and create TOC data
      const headings =
        blogData.long_description.match(/<h[2-4]>.*?<\/h[2-4]>/g) || [];
      const tocData = headings.map((heading) => {
        const text = heading.replace(/<.*?>/g, "");
        const headingId = text.toLowerCase().replace(/\s+/g, "-");
        return { text, headingId };
      });
      setTableOfContents(tocData);
    }
  }, [blogData]);

  useEffect(() => {
    // Handle initial hash navigation
    const hash = window.location.hash;
    if (hash) {
      const id = hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        const offset = 100; // Same offset as above
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  }, []);

  const handleScrollToSection = (headingId) => {
    const element = document.getElementById(`section-${headingId}`);
    if (element) {
      const offset = 100; // Adjust this value based on your header/layout
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
      window.history.pushState(null, "", `#section-${headingId}`);
    }
  };

  const processContent = (content) => {
    return content.replace(
      /<h([2-4])>(.*?)<\/h([2-4])>/g,
      (match, level, content) => {
        // Remove any HTML tags from the content for the ID and link text
        const cleanContent = content.replace(/<[^>]*>/g, "");
        const headingId = cleanContent.toLowerCase().replace(/\s+/g, "-");

        return `<h${level} id="section-${headingId}">
          <a href="#section-${headingId}" 
             style="text-decoration: none; color: inherit;">
            ${cleanContent}
          </a>
        </h${level}>`;
      }
    );
  };

  if (!blogData) {
    return <div className="text-center py-5">Loading...</div>;
  }

  return (
    <div className="blog-wrapper">
      <div className="container py-4">
        <div className="row">
          {/* Table of Contents Section */}
          <div className="col-md-3 d-none d-lg-block">
            <div className="toc-container">
              <h5 className="toc-title">Table of Contents</h5>
              <ul className="toc-list">
                {tableOfContents.map((item, index) => (
                  <li key={index}>
                    <a
                      href={`#section-${item.headingId}`}
                      className="toc-link"
                      onClick={(e) => {
                        e.preventDefault();
                        handleScrollToSection(item.headingId);
                      }}
                    >
                      {item.text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Blog Details Section */}
          <div className="col-md-9">
            <div className="blog-container">
              <h1 className="blog-title d-none d-lg-block">{blogData.blog_name}</h1>
              {/* <p className="blog-author">
                By {blogData.creator} | {new Date(blogData.created_at).toLocaleDateString()}
              </p> */}
              <img
                src={apiUrl + blogData.image}
                alt={blogData.title}
                className="img-fluid rounded mb-4"
                style={{ width: "100%" }}
              />
              {/* <p className="text-muted mb-3" style={{ fontSize: "1.2rem" }}>
                {blogData.short_description}
              </p> */}
              <h2 className="blog-title d-lg-none d-md-block">
                {blogData.blog_name}
              </h2>

              <div
                className="blog-content"
                // style={{ fontSize: "1.1rem", lineHeight: "1.8" }}
                dangerouslySetInnerHTML={{
                  __html: processContent(blogData.long_description),
                }}
              />

              {/* Blog Tags */}
              <div className="mt-4">
                <h5 style={{ fontSize: "1.3rem" }}>Tags:</h5>
                <div>
                  {blogData.tagList.map((tag, index) => (
                    <Link href={`/blog/tag/${tag.tag_info.slug}`} key={index}>
                      <span
                        key={index}
                        className="badge bg-primary me-2"
                        style={{ fontSize: "1rem" }}
                      >
                        {tag.tag_info.name}
                      </span>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;

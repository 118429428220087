import Link from 'next/link';
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const LatestCategories = ({ blogCategories }) => {
  return <>
    {blogCategories && blogCategories.length > 0 &&
      <div className="page-sidebar-box page-sidebar-sticky">
        <h3>Categories</h3>
        <div className="category-list">
          <ul className="ps-2">
            {blogCategories.map((blogCategory, index) => {
              return (
                <li key={index} className="mb-2">
                  <Link
                    href="/blog/category/[slug]"
                    as={`/blog/category/${blogCategory.slug}`}
                    className="d-flex align-items-center">

                    <span className="icon me-3">
                      <FontAwesomeIcon icon={faAngleRight} />
                    </span>
                    <span className="text">
                      {blogCategory.name}
                    </span>

                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    }
  </>;
};

export default LatestCategories;

import Link from 'next/link';
import React from "react";
import CustomImage from "../image/CustomImage";
import { apiUrl } from "../../../config/apiConfig";

const LatestBlog = ({ latestBlogs }) => {
  return <>
    {latestBlogs && latestBlogs.length > 0 &&
      <div className="page-sidebar-box mb-3">
        <h3>Latest Blogs</h3>
        <div className="row">

          {latestBlogs.map((latestBlogData, index) => {
            return (
              <div className="col-6 col-lg-12" key={index}>
                <div className="latest-blog-area">
                  <Link
                    href="/[slug]"
                    as={`/${latestBlogData.slug}`}
                    className="single-latest-blog d-flex">

                    <div className="single-latest-blog-image position-relative">
                      <CustomImage
                        src={latestBlogData.image ? apiUrl + latestBlogData.image : ""}
                        layout="fill"
                        objectFit="cover"
                        size="square"
                      />
                    </div>
                    <div className="single-latest-blog-title">
                      {latestBlogData.blog_name}
                    </div>

                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    }
  </>;
};

export default LatestBlog;

import React from 'react';
import { baseUrl } from '../../../config/apiConfig';
function CommentBox() {
  return (
    <div className="comment-wrapper">
      <div className="comment-image-wrapper">
        <img
          src={baseUrl + '/images/testimonial/3.png'}
          alt="image"
          className="comment-image"
        />
      </div>
      <div className="comment-user-info">
        <div className="comment-name-reply">
          <h5 className="comment-name">Devid Cols</h5>
          <button>Reply</button>
        </div>

        <div className="comment-times">
          <span className="comment-date">Nov 13, 2022 </span>
          <span className="comment-time">5:46 PM</span>
        </div>

        <div className="comment-body">
          <p>
            Thanks for sharing. Very helpful for us. Thanks for sharing. Very
            helpful for us. Thanks for sharing. Very helpful for us. Thanks for
            sharing. Very helpful for us.
          </p>
        </div>
      </div>
    </div>
  );
}

export default CommentBox;
